import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private toastr: ToastController) { }

  public async success(inputMessage: string, title: string = 'Realizado com sucesso!') {
    const toast =
      await this.toastr.create({
        header: title,
        message: inputMessage,
        duration: 3000,
        translucent: false,
        color: 'success',
        position: 'bottom'
      });
    toast.present();
  }

  public async error(inputMessage: string, title: string = 'Erro!') {
    const toast =
      await this.toastr.create({
        header: title,
        message: inputMessage,
        duration: 1000,
        translucent: false,
        color: 'danger',
        position: 'bottom'
      });
    toast.present();
  }

  public async warning(inputMessage: string, duration: number = 4000, title: string = 'Atenção!') {
    const toast =
      await this.toastr.create({
        header: title,
        message: inputMessage,
        duration: duration,
        translucent: false,
        color: 'warning',
        position: 'bottom'
      });
    toast.present();
  }
}
