import { User } from './../models/user.model';
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Store } from './store';
import { IdentityService } from '../services/backend/identity.service';
import { StorageService } from '../services/storage.service';
import { AuthenticationService } from '../services/authentication.service';
import { MessageService } from '../services/message.service';

export class UserState implements User {
  id: string;
  email: string;
  name: string;
  cpf: string;
  cellPhoneNumber: string;
  profile_picture: string;
  role: string;
  createdAt: Date;
  permissions: string[];
}

@Injectable()
export class UserStore extends Store<UserState> {

  constructor(
    private identityService: IdentityService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    storageService: StorageService
  ) {
    super(new UserState(), 't-user', storageService);
  }

  get id(): string {
    return this.state.id;
  }

  get name(): string {
    return this.state.name;
  }

  get email(): string {
    return this.state.email;
  }

  get cpf(): string {
    return this.state.cpf;
  }

  get avatar(): string {
    return this.state.profile_picture;
  }

  async update(data) {
    const state = this.state;
    if (data.id !== undefined) { state.id = data.id; }
    if (data.name !== undefined) { state.name = data.name; }
    if (data.profile_picture !== undefined) { state.profile_picture = data.profile_picture; }
    this.setState(this.state);
    this.authenticationService.setAvatar(data.profile_picture);
    return state;
  }

  set(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.identityService.me().subscribe(data => {
        this.update(data.content[0]);
        resolve(data.content[0]);
        if(data.content[0]?.phone == null) {
          this.messageService.warning('Por favor, preencha os dados restantes no seu perfil de usuario para um cadastro mais completo!');
        }
      }, (error) => {
        console.error(error);
        reject(error);
      });
    });
  }
}
